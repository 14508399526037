
import {
  defineComponent,
  ref,
  unref,
  toRefs,
  onMounted
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  ElMessage,
  ElForm
} from 'element-plus';

import { Rules } from 'async-validator';

import { getBanner } from '@/services/api';

import { useCreateBanner, useUpdateBanner } from '@/composables/api';
import { PartialBanner, BannerType } from '@/interfaces/Banner';

const rules: Rules = {
  name: [
    {
      required: true
    }
  ],
  sort: [
    {
      required: true
    }
  ]
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  // components: {
  //   ImageCropper
  // },
  setup(props) {
    const { isEdit } = toRefs(props);
    const bannerId = useRoute().params.id as string;
    const router = useRouter();

    const page = useRoute().query.page;

    const formRef = ref<typeof ElForm>();
    const formValues = ref<PartialBanner>({});
    const { isLoading: isCreatedLoading, mutate: create } = useCreateBanner();
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdateBanner();

    onMounted(async() => {
      if (isEdit.value) {
        const res = await getBanner({ bannerId });
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        formValues.value = res.data;
      }
    });

    const submitForm = () => {
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (valid) {
          if (isEdit.value) {
            update({ bannerId, data: formValues.value }, {
              onSuccess() {
                ElMessage.success({
                  message: 'success!',
                  type: 'success'
                });
              },
              onError(error: any) {
                ElMessage.error({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            });

            return;
          }

          create({ data: formValues.value }, {
            onSuccess() {
              ElMessage.success({
                message: 'success!',
                type: 'success'
              });

              router.push({
                name: 'list-banners'
              });
            },
            onError(error: any) {
              ElMessage.error({
                message: error.response?.data.message,
                type: 'error'
              });
            }
          });
        }
      });
    };

    return {
      page,
      formValues,
      formRef,
      rules,
      isCreatedLoading,
      isUpdatedLoading,
      submitForm,
      BannerType
    };
  }
});
